<style lang="scss" scoped >
.index {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  canvas {
    top: 0;
  }

  .main {
    flex-grow: 1;
    overflow: hidden;
    display: flex;


    .router-main {
      flex-grow: 1;
      overflow: hidden;
      margin: 20px;
    }
  }
}
</style>

<template>
  <div class="index">
    <custom-header></custom-header>
    <div class="main">
      <custom-menu></custom-menu>
      <div class="router-main">
        <router-view></router-view>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  data() {
    return {
      list: [
        {
          name: "首页",
          router: "home"
        }, {
          name: "客户管理",
          router: "customer",
        }
      ]
    }
  },
  methods: {
    getUserMessage() {
      this.$store.dispatch("getUserMessage")
    },
    linkTo(name) {
      this.$router.push({ name })
    }
  },
  created() {
    this.getUserMessage()
  }
}

</script>
